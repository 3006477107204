<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div>
                        <h1><i class="mdi mdi-playlist-check"
                                        style="transform: scale(1.7);"></i> List of reconciliations</h1>
                        <label style="font-weight: 300;"><i class="mdi mdi-information"></i> This page contains the list of reconciliations   each line represents a match between an invoice and a bank transaction</label>
                        </div>
                        <div >
                            <b-form-group class="col-md-11 mt-2" >
                                <div class="form-inline align-items-center">
      <label class="mr-3">RIB :</label>
      <multiselect placeholder="Select RIB"  v-model="RIB" id="input-rib" :options="selectedRibs" @input="getReconciliations(RIB)" style="width: 40%" />
    </div>
            </b-form-group>
                            
                            
                                
                                <!-- Search -->
                                
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div style="display: inline-flex-row;" class="table-responsive  table-striped mb-0" >
                                <table class="my-custom-table" style="width: 100%;">
                                    <tr v-if="RIB" >
                                        <td >
                                            <h3>Invoices</h3>
                                <div v-for="(listeFactur, index) in listeFacture " :key="listeFactur">
                                <b-table    :items="[listeFactur]" :fields="fields1" responsive="sm"></b-table>
                                  <hr style="border-top: 2px solid rgb(138, 138, 138);">
                                </div>
                                        </td>
                                        <td >
                                            <h3>Transactions</h3>
                                <div v-for="(listeTransaction, index) in listeTransactions" :key="listeTransaction">
                                    <b-table    :items="[listeTransaction]" :fields="fields2" responsive="sm"></b-table>
                                    <hr style="border-top: 2px solid rgb(138, 138, 138);">
                                </div>
                                        </td>
                                </tr>
                                
                                </table>
                            </div>
                            
                            

                        </div>
                    </div>
                </div>
            </div>
        
    </Layout>
</template>

<script>
import { read, utils, write } from 'xlsx';
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from 'vue-multiselect'

export default {
    data() {
        return {
            regulation:{} ,
            regulations:[],
            CompanyName:localStorage.getItem('societe').slice(1, -1),
            selectedRibs: [],
            RIB: "",
            toggleValue: true,
            dataLabels: [],
            selectedIndexes: [],
            data: [],
            listeFacture: [],
            listeTransactions: [],
            


            
            fields1: [
                {
                    key: "Invoice Date",                    
                },
                {
                    key: "Customer Name",
                    
                },
                {
                    key: "Supplier Name",
                    
                },
                {
                    key: "Total",
                    
                },
                {
                    key: "Total all-inclusive",
                    
                },
            ],
            fields2: [
                {
                    key: "Description",                    
                },
                {
                    key: "Value Date",
                    
                },
                {
                    key: "Debit",
                    
                },
                {
                    key: "Credit",
                    
                },
            ],
            reconciliations: [],
        };
    },
    components: {
        Layout,
        Multiselect,
    },
    async mounted() {
        this.getRibs();
        // axios.get(`https://finex.4help.tn/api/v1/regulations/${localStorage.getItem('societe').slice(1, -1)}`).then((res) => {
        //     this.CustNames = res.data.map((client) => client["Client Name"]);
        // })
        
    },
    computed: {
        rows() {
            return this.listeFacture.length;
        },
    },
    methods: {
        getRibs() {
      axios.get(`https://finex.4help.tn/api/v1/getribss/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { res.data.map(obj => this.selectedRibs.push(obj.RIB)) })
    },
       
        
        async getReconciliations(RIB) {
            if (this.RIB) {
              await axios.get(`https://finex.4help.tn/api/v1/reconciliations/${localStorage.getItem("societe").slice(1, -1)}/${RIB}`)
           .then(res => { this.reconciliations = res.data
            this.listeFacture = []
            this.listeTransactions = []
            this.reconciliations.forEach(element  => {
                console.log(element['Invoice'])
                this.listeFacture.push({"Invoice Date":element['Invoice']["Invoice Date"],
                "Customer Name":element['Invoice']["Customer Name"],
                "Supplier Name":element['Invoice']["Supplier Name"],
                "Total":element['Invoice']["Total"],
                "Total all-inclusive":element['Invoice']["Total all-inclusive"]})
            });

            this.reconciliations.forEach(element  => {
                console.log(element['Transaction'])
                this.listeTransactions.push({"Description":element['Transaction']["Description"],
                "Value Date":element['Transaction']["Value Date"],
                "Debit":element['Transaction']["Debit"],
                "Credit":element['Transaction']["Credit"]                
                })
            });
            console.log("/////////////",this.listeFacture)
            })
           .catch(err => { if(err.message=="Request failed with status code 404") { swal("Error", "No Reconciliations for this RIB", "error");
        this.RIB = "" } })
              
            }
           
        },
        

     
        
        
       
        
    }
};
</script>

<style scoped>
.my-custom-tablee:not(:first-of-type) thead {
  display: none;
}
.my-custom-table tr:hover {
  background-color: transparent !important; /* or set it to the default row color */
}
.form-container {
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.accueil {
    text-align: center;
    padding: 4rem;
}

.accueil {
    text-align: center;
}

.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}



</style>

